import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
export default function News(props) {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("Newsdata.json").then((res) => setData(res.data));
  }, []);

  return (
    <div className="news_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "News"}{" "}
        {props.language === "LT" && "Naujienos"}
      </h1>
      <div className="news_boxes">
        {data.map((item) => {
          return (
            <div
              className="news_box"
              key={Math.random()}
              onClick={() => navigate("/news/" + item.url)}
            >
              <img src={item.image} alt="" className="news_photo" />
              <h2 className="news_text">
                {props.language === "EN" && item.name}{" "}
                {props.language === "LT" && item.lt_name}
              </h2>
            </div>
          );
        })}
      </div>
    </div>
  );
}
