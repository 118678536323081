import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./Tickets.css"
import About from "./components/About";
import Compositions from "./components/Compositions";

import Contact from "./components/Contact";
import DetailComp from "./components/DetailComp";
import DetailLPEP from "./components/DetailLPEP";

import DetailNews from "./components/DetailNews";
import DetailSound from "./components/DetailSound";
import Discography from "./components/Discography";
import Intro from "./components/Intro";
import Latest from "./components/Latest";
import Navbar from "./components/Navbar";
import News from "./components/News";
import Singles from "./components/Singles";
import Soundtracks from "./components/Soundtracks";
import Tickets from "./components/Tickets";
import Success from "./components/Success";
import axios from "axios";

function App() {
  const [language, setLanguage] = useState();
  useEffect(() => {
   
  
    
    //Language
    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "LT");
      setLanguage("LT");
    } else {
      setLanguage(localStorage.getItem("language"));
    }
  }, []);

  return (
    <>
      <Router>
        <Navbar language={language} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Intro language={language} />
                <Latest language={language} />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About language={language} />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Contact language={language} />
              </>
            }
          />
          <Route
            path="/news"
            element={
              <>
                <News language={language} />
              </>
            }
          />
          <Route
            path="/news/:url"
            element={
              <>
                <DetailNews language={language} />
              </>
            }
          />
          <Route
            path="/discography"
            element={
              <>
                <Discography language={language} />
              </>
            }
          />
          <Route
            path="/discography/:url"
            element={
              <>
                <DetailLPEP language={language} />
              </>
            }
          />
          <Route
            path="/singles/:url"
            element={
              <>
                <Singles language={language} />
              </>
            }
          />
          <Route
            path="/compositions"
            element={
              <>
                <Compositions language={language} />
              </>
            }
          />
          <Route
            path="/compositions/:url"
            element={
              <>
                <DetailComp language={language} />
              </>
            }
          />
          <Route
            path="/soundtracks"
            element={
              <>
                <Soundtracks language={language} />
              </>
            }
          />
          <Route 
            path="/soundtracks/:url"
            element={
              <>
                <DetailSound language={language} />
              </>
            }
          />
          <Route
            path="/tickets"
            element={
              <>
                <Tickets language={language} />
              </>
            }
          />
           <Route
            path="/success"
            element={
              <>
                <Success language={language} />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
