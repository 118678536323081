import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isBurger, setIsBurger] = useState(false);
  const [music, onMusic] = useState(false);
  const [logo, setLogo] = useState("");
  const [isLang, setIsLang] = useState(false);
  const [language, setLanguage] = useState("");
  const nav = useRef();
  let navigate = useNavigate();

  const variants = {
    open: { display: "block", marginTop: "2rem" },
    closed: { display: "none", marginTop: "0rem" },
  };
  const burger_variants = {
    open: { display: "block", opacity: 1 },
    closed: { display: "none", opacity: 0.5 },
  };
  const changeArrow = (e) => {
    if (!music) {
      chevron.current.classList = "fa-solid fa-chevron-right";
    } else {
      chevron.current.classList = "fa-solid fa-chevron-down";
    }
  };
  useEffect(() => {
    setLanguage(props.language);
    if (props.language === "LT")
      nav.current.style = "grid-template-columns:1fr 1fr 1fr 0.6fr 1fr 0.3fr";
    if (window.innerWidth <= 800 && logo !== undefined) {
      setLogo("/images/logo.png");
    } else if (logo !== undefined) {
      setLogo(window.location.origin + "/images/logo_pc.jpg");
    }
  }, [logo, props.language]);

  const chevron = useRef();
  window.addEventListener("resize", () => {
    setIsBurger(false);
    if (window.innerWidth <= 800 && logo !== undefined) {
      setLogo("/images/logo.png");
    } else if (logo !== undefined) {
      setLogo(window.location.origin + "/images/logo_pc.jpg");
    }
  });
  const toLt = () => {
    setLanguage("LT");
    localStorage.setItem("language", "LT");
    window.location.reload(false);
  };
  const toEn = () => {
    setLanguage("EN");
    localStorage.setItem("language", "EN");
    window.location.reload(false);
  };
  return (
    <nav className="navbar">
      <div className="nav_container">
        <div className="nav_left">
          <img
            src={logo}
            className="nav_logo"
            alt=""
            onClick={() => navigate("/")}
          />
        </div>

        <ul className="nav_links" ref={nav}>
          <li
            className="nav_link"
            onClick={() => {
              navigate("/");
            }}
          >
            {language === "EN" && "Home"} {language === "LT" && "Pagrindinis"}
          </li>
          <li
            className="nav_link"
            onClick={() => {
              navigate("/news");
              setIsOpen(false);
            }}
          >
            {language === "EN" && "News"} {language === "LT" && "Naujienos"}
          </li>
          <li
            className="nav_link"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {language === "EN" && "Music"} {language === "LT" && "Muzika"}{" "}
            <i className="fa-solid fa-chevron-down"></i>
          </li>
          <li
            className="nav_link"
            onClick={() => {
              navigate("/about");
              setIsOpen(false);
            }}
          >
            {language === "EN" && "About"} {language === "LT" && "Apie"}
          </li>
          <li
            className="nav_link"
            onClick={() => {
              navigate("/contact");
              setIsOpen(false);
            }}
          >
            {language === "EN" && "Contact"} {language === "LT" && "Susisiekti"}
          </li>
          <li
            className="nav_link"
            onClick={() => setIsLang((isLang) => !isLang)}
          >
            {language} <i className="fa-solid fa-chevron-down"></i>
          </li>
          <motion.ul
            className="nav_dropdown"
            animate={isOpen ? "open" : "closed"}
            variants={variants}
          >
            <li
              className="nav_option"
              onClick={() => {
                navigate("/discography");
                setIsOpen(false);
              }}
            >
              {props.language === "EN" && "Discography"}{" "}
              {props.language === "LT" && "Diskografija"}
            </li>
            <li
              className="nav_option"
              onClick={() => {
                navigate("/compositions");
                setIsOpen(false);
              }}
            >
              {props.language === "EN" && "Compositions"}{" "}
              {props.language === "LT" && "Kompozicijos"}
            </li>
            <li
              className="nav_option"
              onClick={() => {
                navigate("/soundtracks");
                setIsOpen(false);
              }}
            >
              {props.language === "EN" && "Soundtracks"}{" "}
              {props.language === "LT" && "Garso takeliai"}
            </li>
          </motion.ul>
          <motion.ul
            className="lang_dropdown"
            animate={isLang ? "open" : "closed"}
            variants={variants}
          >
            <li
              className="nav_option"
              onClick={() => {
                toEn();
                setIsLang(false);
              }}
            >
              EN
            </li>
            <li
              className="nav_option"
              onClick={() => {
                toLt();
                setIsLang(false);
              }}
            >
              LT
            </li>
          </motion.ul>
        </ul>
        <i
          className="fa-solid fa-bars nav_burger"
          onClick={() => setIsBurger(!isBurger)}
        ></i>
        <motion.ul
          className="nav_burger_detail"
          animate={isBurger ? "open" : "closed"}
          variants={burger_variants}
        >
          <ul className="nav_links_mobile">
            <li
              className="nav_link_mobile"
              onClick={() => {
                navigate("/");
                setIsBurger(false);
              }}
            >
              {language === "EN" && "Home"} {language === "LT" && "Pagrindinis"}
            </li>
            <li
              className="nav_link_mobile"
              onClick={() => {
                navigate("/news");
                setIsBurger(false);
              }}
            >
              {language === "EN" && "News"} {language === "LT" && "Naujienos"}
            </li>
            <li
              className="nav_link_mobile"
              onClick={(e) => {
                onMusic(!music);
                changeArrow(e);
              }}
            >
              {language === "EN" && "Music"} {language === "LT" && "Muzika"}{" "}
              <i className="fa-solid fa-chevron-down" ref={chevron}></i>
            </li>
            <div
              className="nav_mobile_flex"
              style={{ display: music === false && "none" }}
            >
              <li
                className="nav_link_mobile nav_expanded"
                style={{ display: music === false && "none" }}
                onClick={() => {
                  navigate("/discography");
                  setIsBurger(false);
                  onMusic(false);
                }}
              >
                {language === "EN" && "Discography"}{" "}
                {language === "LT" && "Diskografija"}
              </li>
              <div className="sepparator"></div>
              <li
                className="nav_link_mobile nav_expanded"
                style={{ display: music === false && "none" }}
                onClick={() => {
                  navigate("/compositions");
                  setIsBurger(false);
                  onMusic(false);
                }}
              >
                {language === "EN" && "Compositions"}{" "}
                {language === "LT" && "Kompozicijos"}
              </li>
              <li
                className="nav_link_mobile nav_expanded"
                style={{ display: music === false && "none" }}
                onClick={() => {
                  navigate("/soundtracks");
                  setIsBurger(false);
                  onMusic(false);
                }}
              >
                {language === "EN" && "Soundtracks"}{" "}
                {language === "LT" && "Garso takeliai"}
              </li>
            </div>
            <li
              className="nav_link_mobile"
              onClick={() => {
                navigate("/about");
                setIsBurger(false);
              }}
            >
              {language === "EN" && "About"} {language === "LT" && "Apie"}
            </li>
            <li
              className="nav_link_mobile"
              onClick={() => {
                navigate("/contact");
                setIsBurger(false);
              }}
            >
              {language === "EN" && "Contact"}{" "}
              {language === "LT" && "Susisiekti"}
            </li>
            <ul className="nav_languages">
              <li
                className="nav_language"
                style={{
                  textDecoration: props.language === "EN" && "underline",
                }}
                onClick={() => {
                  toEn();
                  setIsLang(false);
                }}
              >
                EN
              </li>
              <li
                className="nav_language"
                style={{
                  textDecoration: props.language === "LT" && "underline",
                }}
                onClick={() => {
                  toLt();
                  setIsLang(false);
                }}
              >
                LT
              </li>
            </ul>
          </ul>
        </motion.ul>
      </div>
    </nav>
  );
}
