import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function Compositions(props) {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get("Compositions.json").then((res) => setData(res.data));
  }, []);
  return (
    <div className="compositions_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "Compositions"}{" "}
        {props.language === "LT" && "Kompozicijos"}
      </h1>
      <div className="compositions">
        {data !== "" &&
          data.map((item) => {
            return (
              <div
                className="compositions_box"
                key={Math.random()}
                onClick={() => navigate("/compositions/" + item.url)}
              >
                <img src={item.image} alt="" className="compositions_image" />
                <h2 className="compositions_h2">{item.name}</h2>
              </div>
            );
          })}{" "}
      </div>
    </div>
  );
}
