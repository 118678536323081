import axios from "axios";
import React, { useRef, useState } from "react";

export default function Contact(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("Music");
  const [message, setMessage] = useState("");
  const [errText, setErr] = useState("");
  const alertbox = useRef();
  const btn = useRef();
  //{props.language === "EN" && ""} {props.language === "LT" && ""}
  const send = (e) => {
    if (message !== "" && topic !== "" && email !== "" && name !== "") {
      axios
        .post(
          "https://us-central1-servers-72707.cloudfunctions.net/st/send_email",
          {
            topic,
            email,
            message,
            name,
          }
        )
        .then((res) => console.log(res));
      alertbox.current.style.display = "block";
      alertbox.current.style.border = "solid green 1px";
      btn.current.style.display = "none";
      if (props.language === "EN") {
        setErr("Message sent successfully");
      } else {
        setErr("Žinutė išsiųsta");
      }

      setName("");
      setEmail("");
      setMessage("");
      setTimeout(() => {
        alertbox.current.style.display = "none";
        btn.current.style.display = "block";
      }, 3000);
    } else {
      alertbox.current.style.display = "block";
      alertbox.current.style.border = "solid red 1px";
      btn.current.style.display = "none";
      if (props.language === "EN") {
        setErr("Please fill all the empty fields");
      } else {
        setErr("Užpildykite visus tuščius laukus.");
      }

      setTimeout(() => {
        alertbox.current.style.display = "none";
        btn.current.style.display = "block";
      }, 3000);
    }

    e.preventDefault();
  };
  return (
    <div className="contact_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "Contact"}{" "}
        {props.language === "LT" && "Susisiekti"}
      </h1>
      <div className="socialmedia">
        <a
          href="https://twitter.com/director_1406?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-twitter"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/paulius-stanulionis-867a37145/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-linkedin-in"></i>
        </a>
        <a
          href="https://www.facebook.com/paulius.stanulionis"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        <a
          href="https://open.spotify.com/artist/5FJBK9uaYYyCt6hYDVo5yL"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <i className="fa-brands fa-spotify"></i>{" "}
        </a>
      </div>
      <h2 className="h2 text_center contact_question">
        {props.language === "EN" && "Have a question?"}{" "}
        {props.language === "LT" && "Turite klausimų?"}
      </h2>
      <h3 className="h3 text_center">
        {props.language === "EN" &&
          "Shoot up a message with the form below! Can't wait to talk to you!"}
        {props.language === "LT" &&
          "Raštelkite žinutę su žemiau esančia forma! Nekantrauju išgirsti iš jūsų!"}
      </h3>

      <form className="contact_form">
        <label htmlFor="name">
          {" "}
          {props.language === "EN" && "Name"}{" "}
          {props.language === "LT" && "Vardas"}
        </label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="email">
          {" "}
          {props.language === "EN" && "Email"}{" "}
          {props.language === "LT" && "El.paštas"}
        </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="topic">
          {" "}
          {props.language === "EN" && "Topic"}{" "}
          {props.language === "LT" && "Tema"}
        </label>
        <select
          name="topic"
          id=""
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
        >
          <option value="Music">
            {" "}
            {props.language === "EN" && "Music"}{" "}
            {props.language === "LT" && "Muzika"}
          </option>
          <option value="Collaboration">
            {" "}
            {props.language === "EN" && "Collaboration"}{" "}
            {props.language === "LT" && "Bendradarbiavimas"}
          </option>
          <option value="Business">
            {" "}
            {props.language === "EN" && "Business"}{" "}
            {props.language === "LT" && "Verslas"}
          </option>
          <option value="Other">
            {" "}
            {props.language === "EN" && "Other"}{" "}
            {props.language === "LT" && "Kita"}
          </option>
        </select>

        <label htmlFor="message">
          {" "}
          {props.language === "EN" && "Message"}{" "}
          {props.language === "LT" && "Žinutė"}
        </label>
        <textarea
          name=""
          id=""
          cols="20"
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button className="contact_btn" ref={btn} onClick={(e) => send(e)}>
          {props.language === "EN" && "Send!"}{" "}
          {props.language === "LT" && "Siųsti"}
        </button>
        <div className="contact_error" ref={alertbox}>
          {errText}
        </div>
      </form>
    </div>
  );
}
