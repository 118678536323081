import React from "react";

export default function About(props) {
  return (
    <div className="about_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "About"}
        {props.language === "LT" && "Apie"}
      </h1>

      <p className="about_desc">
        <img src="/images/about_photo.JPG" className="about_photo" alt="" />

        {props.language === "EN" && (
          <>
            <b>Paulius Stanulionis</b> is a Lithuanian artist, composer and
            producer, based in Vilnius, Lithuania. With a wide range of
            soundtracks, albums, and academical compositions under his belt,
            Paulius has been writing down and realizing his musical ideas for
            more than half a decade. From symphonic harmonies, modernism-filled
            pieces and epic themes, to jazz, funky rhythms and a mix of rock n'
            roll - Paulius proved himself comfortable with all.
            <br /> <br />
            The composer's journey began in 2016, when Paulius decided to pick
            up music writing programs and start creating some simple melodies.
            This put a strong foundation into place, as with every new project,
            he started to feel more comfortable and began writing more
            sophisticated pieces. Under the moniker "DirectorMusic", he got the
            chance to play with symphonic sounds, jazz, electronic music. It all
            came down to be a wild ride: ultimately, Paulius had the opportunity
            to work with such gaming and film industry giants as Roblox,
            Jazwares, Warner Bros and Marvel. <br /> <br />
            But music is a medium full of diversity, so in 2019, Paulius made a
            decision to try his hands in songwriting, which, in turn, became a
            burning passion until this day. The idea launched forward with
            relative ease, as all the production knowledge and computer skills
            gave way to assembling meaningful projects, which became albums or
            other similar projects. <br /> <br />
            On top of that, Paulius pursued a musical education since primary
            school. He attended the men & boys' choir "Ąžuoliukas", graduated
            Vilnius B. Dvarionas Ten-Year Music School and is pursuing further
            education in Lithuanian Music & Theater Academy, hoping to enroll on
            a Music Composition BA degree class. In recent years, Paulius has
            been increasingly focusing on the world of music academia, writing
            various compositions with intent to retain value and meaning behind
            sometimes rather peculiar-sounding pieces. <br /> <br />
            As for the future, Paulius hopes to get even more involved with the
            world of music, from production and performance, to songwriting and
            professional composition. Long live music!
          </>
        )}
        {props.language === "LT" && (
          <>
            Paulius Stanulionis yra lietuvių atlikėjas, kompozitorius ir
            prodiuseris, kilęs iš Vilniaus. Su nemažu garso takelių, albumų bei
            akademinių kompozicijų bagažu Paulius rašo bei realizuoja savo
            muzikines idėjas jau daugiau nei pusę dešimtmečio. Nuo simfoninių
            harmonijų, modernizmo kupinų pjesių bei epinių temų, iki džiazo,
            funk'o ritmų bei įvairios rock'o muzikos - Paulius įrodė, jog visa
            tai jam nesvetima. <br /> <br />
            Kompozitoriaus kelias prasidėjo 2016-aisiais metais, kuomet Paulius
            nusprendė išbandyti muzikos kūrimo programas bei pradėjo komponuoti
            įvairias paprastas melodijas. Visa tai padėjo stiprius pagrindus,
            nes su kiekvienu nauju projektu jis ėmė jaustis vis laisviau ir
            pradėjo rašyti sudėtingesnes pjeses. Po "DirectorMusic" pseudonimu
            jis gavo progą žaisti su simfoniniais sąskambiais, džiazu,
            elektronine muzika. Visa tai galiausiai patapo patrakusia kelione:
            Paulius turėjo galimybę dirbti su įvairiais žaidimų bei kino
            industrijos milžinais kaip Roblox, Jazwares, Warner Bros ir Marvel.{" "}
            <br /> <br />
            Bet muzika yra įvairovės kupina terpė, todėl 2019-aisiais metais
            Paulius išdrįso išbandyti save dainų rašyme, kuris jaunajam kūrėjui
            tapo degančia aistra iki šių dienų. Ši idėja nuo žemės pakilo gana
            lengvai, nes iki tol sukaupta prodiusavimo bei kompiuterinės
            technikos patirtis nuskynė kelią į prasmingų projektų dėliojimą, kas
            vėliau išaugo iki albumų ar kitų panašių projektų. <br /> <br />
            Šalia to, Paulius siekė muzikinio išsilavinimo nuo pradinės mokyklos
            laikų. Jis lankė vyrų bei jaunuolių chorą "Ąžuoliukas", baigė
            Vilniaus B. Dvariono dešimtmetę muzikos mokyklą ir šiuo metu siekia
            tolimesnio išsilavinimo Lietuvos Muzikos ir Teatro Akademijoje,
            tikėdamasis įstoti į Muzikos kompozicijos bakalauro studijas.
            Pastaraisiais metais Paulius skiria vis daugiau dėmesio muzikos
            akademikos pasauliui, rašo įvairias kompozicijas su intencija
            išlaikyti apskaičiuoto, tačiau savotiško skambėsio pjesių bei
            stambesnės formos kūrinių vertę bei prasmę. <br /> <br />
            Ateičiai Paulius tikisi dar labiau įsitraukti į muzikos pasaulį, nuo
            prodiusavimo ir atlikimo, iki dainų rašymo bei profesionalios
            kompozicijos. Tegyvuoja muzika!
          </>
        )}
      </p>
    </div>
  );
}
