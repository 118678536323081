import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function Discography(props) {
  let navigate = useNavigate();
  const [data, setData] = useState("");
  useEffect(() => {
    axios.get("Soundtracks.json").then((res) => setData(res.data));
  }, []);
  return (
    <div className="soundtracks_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "Soundtracks"}{" "}
        {props.language === "LT" && "Garso takeliai"}
      </h1>

      <div className="soundtracks">
        {data !== "" &&
          data.map((item) => {
            return (
              <div
                className="discography_box soundtrack_box"
                key={Math.random()}
                onClick={() => navigate("/soundtracks/" + item.url)}
              >
                <img
                  src={item.image}
                  alt=""
                  className="discography_image soundtrack_image"
                />
                <h2 className="discography_h2 soundtrack_text">
                  {item.album_name}
                </h2>
              </div>
            );
          })}{" "}
      </div>
    </div>
  );
}
