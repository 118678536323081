import React, { useEffect, useState } from "react";
import axios from "axios";
export default function DetailNews(props) {
  const [post, setPost] = useState("");

  useEffect(() => {
    axios.get(window.location.origin + "/Newsdata.json").then((res) => {
      const url = window.location.href.split("/")[4];
      const filtered = res.data.filter((item) => {
        return item.url === url;
      });
      setPost(filtered[0]);
    });
  }, []);

  return (
    <div className="detail_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && post.name}{" "}
        {props.language === "LT" && post.lt_name}
      </h1>
      <img src={post.big_image} alt="" className="detail_img" />
      <p className="detail_desc">
        {props.language === "EN" && post.desc}{" "}
        {props.language === "LT" && post.lt_desc}
      </p>
    </div>
  );
}
