import React from "react";

export default function Intro(props) {
 
  return (
    <div className="intro">
      <img src="images/main_cover.png" alt="" className="intro_image_pc" />
      <img
        src="images/cover_mobile.png"
        alt=""
        className="intro_image_mobile"
      />
      <div className="welcometext">
        <h1 className="h1">PAULIUS STANULIONIS</h1>
        <p className="p">
         {props.language==="EN" ? "artist, composer, producer, musician & coffee addict" : "artistas, kompozitorius, muzikantas. priklausomas nuo kavos"} 
        </p>
      </div>
    </div>
  );
}
