import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import QRcode from 'qrcode.react'
import { jsPDF } from 'jspdf'
import axios from 'axios';
import { createPortal } from 'react-dom';

const pdf = new jsPDF("p", "mm", "a4");

const Success = (props) => {
  
   const [status,setStatus]=useState(false)
   const [errorcode,setErrorcode] = useState("");
   const [elements, setElements] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [notif,setNotif] = useState("");
  const [generated,setGenerated] = useState(false);
  const loader = useRef();
  const emailbtn = useRef();
  const email = localStorage.getItem("email");
  const amount = Number(localStorage.getItem("amount"));
    useEffect(()=>{
     
      setTimeout(()=>{
        if(loader.current.style.display==="block"){
          window.location.reload();
        }
      },7000)
     axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/verifypurchase",{session_id:window.location.href.split("?")[1]}).then((res)=>{
        if(res.data.payment_status==="paid"){
        setStatus(true);
        generateTicket()
   
        }else if(window.location.href.split("?")[1]!==undefined){
        if(window.location.href.split("?")[1].includes("payment_token")){
            axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/validatemontonio",{payment_token:window.location.href.split("=")[1]}).then((res)=>{
              if(res.data.success===true){
                setStatus(true);
                  generateTicket()
                  axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/todatabase",{hash:localStorage.getItem("data"),email:localStorage.getItem("email"),amount:amount}).then(() =>{ loader.current.style.display="none";  
      
                })
              }
            })
        }
       
          loader.current.style.display="none"
        
        }else{
          window.location.replace("/tickets")
        }}
   
      )
       
    },[]) 

    useEffect(()=>{

    
    },[])


    const save = () =>{
      if(elements!==[]){
        for(let i=0;i<amount;i++){
      
          html2canvas(document.querySelector(".document"+i.toString()),{
            scale: 1.85
          }).then((canvas) => {
           
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
       
             pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.addPage()
            
       
          
          });
        }
        loader.current.style.display="none" 
       
      }
     
 
    }



    const generateTicket = () =>{
      if(generated===false){
        const data =JSON.parse(localStorage.getItem("data"));
        const temp = [];
        

         temp.push(<div className={'document0'} key={Math.random()}> <h2 className='name'>Paulius Stanulionis | Naktinis Vilniaus Avilys</h2>
        {amount!==1 && <div className='whichticket'>
        {amount+" Bilietai"}
         </div>}
          <div className='emailhold'>{email}</div> 
           <div className='qrcodedata'><QRcode value = {data[0]} id="qrcode"/></div> 
             <p>2022.09.15</p>
             <h2 className='door'>Durys atsidaro 19:00</h2>
               <h3 className='start'>Renginio pradžia 20:00</h3>
             
                 <p>Organizatorius: Paulius Stanulionis</p>
                 <p className='email_ticket'>paulius.stanulionis@gmail.com</p></div>)
          
          setElements(temp);
          setGenerated(true);
      }
     
      
   
    }

        const peremail = () =>{
          loader.current.style.display="block";
      
          const blobs=[];


                html2canvas(document.querySelector(".document0"),{
                  scale: 1.85
                }).then((canvas) => {
                  const imgData = canvas.toDataURL("image/jpeg", 1.0);
                   pdf.addImage(imgData, 'JPEG', 0, 0);
                   blobs.push(pdf.output("datauristring"));
                 
                   loader.current.style.display="none"
         
                  
                      axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/sendmail",{ blobs:blobs, email}).then((res)=>{
                        loader.current.style.display="none"
                        emailbtn.current.style.backgroundColor="#333";
                        emailbtn.current.style.cursor="not-allowed";
                        setNotif(`Laiškas išsiųstas. Jei jo negavote, bandykite dar kartą už 20s šiai žinutei dingus iš ekrano`);
                        
                        setTimeout(()=>{
                          setDisabled(false);
                          emailbtn.current.style.backgroundColor="#4d4d4d";
                          emailbtn.current.style.cursor="pointer";
                          setNotif("")
                        },20000)
                      })
                    
                });
                
               
             
                
          


  
        }

    
    return (

      <>
      <div className='loader' ref={loader}>
      <div className='loaderbck'> </div>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
     
     
     
        <div className="success" style={{display:status===false&&"none"}}>
           <h1 className="h1 text_center" style={{marginTop:"3rem"}}>{props.language==="LT" ?"Bilietas įsigytas sėkmingai!" : "Ticket purchased successfully!"}</h1>
           <h3 className='text_center ticket_downloaded'>Atsisiųskite bilietą į el. paštą spausdami mygtuką žemiau</h3>
           <div className='buttons_success'>
            <button className='getticket_email' disabled={disabled} ref={emailbtn} onClick={() =>peremail()}>{props.language==="LT" ? "Gauti biletą paštu" : "Get ticket per mail"}</button>
            
            </div>
            <div className='documents'>
          {elements.map((item)=>{
            return item;
          })}
        </div>
       
        </div>
        <div className='notif' style={{display:notif===""&&"none"}}>
        {notif}
        </div>
       
        <img src ="/images/sck.jpeg" className='successpic'></img>

        </>
    );
}

export default Success;
