import React, { useEffect, useRef } from 'react';
import "../Tickets.css"
import axios from 'axios';
import { useState } from 'react';



const Tickets = () => {


  useEffect(()=>{
    loader.current.style.display="none";
  })

    const [email,setEmail] = useState("");
    const [amount, setAmount] = useState(1)
    const [errormenu,setErrormenu]=useState("");
    const loader = useRef();
    const generateQr = (e) =>{
      loader.current.style.display="block";
        const day = new Date().getTime()+60;
     
        if(email!==""&&email.includes("@")&&email.includes(".")&&amount<=5){
          axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/generatekey",{email,amount}).then((res) =>{
            if(res.data!==false){  localStorage.setItem("data",JSON.stringify(res.data));
            localStorage.setItem("email",email);
            localStorage.setItem("amount",amount)
        // axios.post("http://localhost:5001/servers-72707/us-central1/qr/loadstripe",{amount}).then((res)=>window.location.replace(res.data))
            axios.post("https://us-central1-servers-72707.cloudfunctions.net/qr/montonio",{reference:email,amount}).then((res)=>{  loader.current.style.display="none";window.location.href="https://payments.montonio.com?payment_token="+res.data})}else{
              setErrormenu("Bilietas šiuo paštu jau egzistuoja")
              setTimeout(()=>setErrormenu(""),3000)
              loader.current.style.display="none";
            }
          
    })
      
         e.preventDefault();
       }else if(amount>5&&amount<0){
        setErrormenu("Vienu metu daugiausiai galima pirkti 5 bilietus")
        setTimeout(()=>setErrormenu(""),2000)
       }
       else{
        setErrormenu("Patikrinkite el pašto adresą")
        loader.current.style.display="none";
        setTimeout(()=>setErrormenu(""),2000)
       }
     
      }
    return (
        <div className="tickets_container">
        <div className='loader' ref={loader}>
      <div className='loaderbck'> </div>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
        <h1 className="h1 text_center">Pirkti Bilietą </h1>
        <p className='concert_desc'>Paulius Stanulionis pirmą kartą rengia savo pasirodymą kaip solo atlikėjas, su artimais bičiuliais užnugaryje. Nuo trankių gitarų iki visiško pasinėrimo į jausmus - Paulius įneš gyvybės savo dainoms, kurias pirmąkart atliks gyvai. </p>
        <div className='ticket_intro_container'>
          <div className='ticket_right'>
          <h2 className='concert_name'>Pauliaus Stanulionis | Naktinis Vilniaus Avilys</h2>
        <img src="/stanis_bck.png" alt="" className='concert_photo'/>
          </div>
          <div className='ticket_left'>
          
        <div className='ticket_input'>
          <p className='ticket_desc'>Žemiau įveskite el. paštą, į kurį norėtumėte gauti bilietus į koncertą. Po to jus nukels į mokėjimo sistemą, kur įvykdžius apmokėjimą Jums įkris bilietai, kuriuos parodysite įėjime. PASTABA: Vienu el. paštu galima daryti <b>tik vieną</b> užsakymą, tačiau galima pirkti kelis bilietus iš karto!</p>
          <div className='errormenu' style={{display:errormenu===""&&"none"}}>{errormenu}</div> 
          <label>El.paštas</label>
          <input type="email" name="" value={email} onInput={(e) =>setEmail(e.target.value)}/>
          <label>Bilietų kiekis</label>
          <input type="number" name="" value={amount} onInput={(e) =>setAmount(e.target.value)}/>
          </div>
          <div className='ticket_buttons'> 
          <button onClick={(e) =>generateQr(e)}>Pirkti Bilietą</button>
        
          </div>
      
       </div>
        </div> 
       
       

      
      
    </div>
    );
}

export default Tickets;
