import axios from "axios";
import React, { useEffect, useState } from "react";

export default function DetailComp(props) {
  const [data, setData] = useState("");
  const url = window.location.href.split("/")[4];
  useEffect(() => {
    axios.get(window.location.origin + "/Compositions.json").then((res) => {
      const filtered = res.data.filter((item) => {
        return item.url === url;
      });
      setData(filtered[0]);
    });
  }, [url]);
  return (
    <div className="compositions_detail_container">
      {data !== "" && (
        <div className="compositions_grid">
          <div className="compositions_left">
            <img src={data.image} alt="" className="compositions_image" />
            <h2 className="compositions_detail_h2">{data.name}</h2>
          </div>
          <div className="compositions_right">
            <h2 className=" desc_h2 compositions_desc_h2">
              {props.language === "EN" && "DESCRIPTION"}{" "}
              {props.language === "LT" && "APRAŠYMAS"}
            </h2>
            <p className=" desc_p compositions_desc_p">
              {props.language === "EN" && data.desc}{" "}
              {props.language === "LT" && data.lt_desc}
            </p>
          </div>
        </div>
      )}
      <div className="compositions_performance">
        <h2 className="compositions_desc_h2_p">
          {props.language === "EN" && "PERFORMANCE"}{" "}
          {props.language === "LT" && "ATLIKIMAS"}
        </h2>
        <iframe
          src={data.embed}
          title="Paulius Stanulionis - Žmonės Pro Rakto Skylutę"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="compositions_video"
        ></iframe>
      </div>
    </div>
  );
}
