import React, { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";
export default function Latest(props) {
  const [latest, setLatest] = useState([]);
  useEffect(() => {
    axios.get("Latest.json").then((res) => setLatest(res.data));
  }, []);
  let navigate = useNavigate();
  return (
    <div className="latest_container">
      <h1 className="h1 text_center latest_h1">
        {props.language === "LT" && "NAUJAUSIA"}
        {props.language === "EN" && "LATEST"}
      </h1>
      {latest.map((item) => {
        return (
          <div className="latest_desc" key={Math.random()}>
            <h2>
              {props.language === "LT" && item.lt_pav}
              {props.language === "EN" && item.pav}
            </h2>
            <p>
              {props.language === "LT" && item.lt_desc}
              {props.language === "EN" && item.desc}
            </p>
            <button
              className="latest_btn"
              onClick={() => navigate("/" + item.link_to)}
            >
              {props.language === "LT" && "DAUGIAU"}
              {props.language === "EN" && "VIEW MORE"}
            </button>
          </div>
        );
      })}
    </div>
  );
}
