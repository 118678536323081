import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function Discography(props) {
  let navigate = useNavigate();
  const [data, setData] = useState("");
  useEffect(() => {
    axios.get("Discography.json").then((res) => setData(res.data));
  }, []);
  return (
    <div className="discography_container">
      <h1 className="h1 text_center">
        {props.language === "EN" && "Discography"}{" "}
        {props.language === "LT" && "Diskografija"}
      </h1>
      <h2 className="text_center">
        {props.language === "EN" && "LP/EP"}{" "}
        {props.language === "LT" && "ALBUMAI/EP"}
      </h2>
      <div className="discography">
        {data !== "" &&
          data.LPEP.map((item) => {
            return (
              <div
                className="discography_box"
                key={Math.random()}
                onClick={() => navigate("/discography/" + item.url)}
              >
                <img src={item.image} alt="" className="discography_image" />
                <h2 className="discography_h2">
                  {item.album_name} ({item.date})
                </h2>
              </div>
            );
          })}{" "}
      </div>
      <h2 className="text_center singles_text">
        {props.language === "EN" && "SINGLES"}{" "}
        {props.language === "LT" && "SINGLAI"}
      </h2>
      <div className="singles">
        {data !== "" &&
          data.SINGLES.map((item) => {
            return (
              <div
                className="discography_box"
                key={Math.random()}
                onClick={() => navigate("/singles/" + item.url)}
              >
                <img src={item.image} alt="" className="discography_image" />
                <h2 className="discography_h2">
                  {item.album_name} <br />({item.date})
                </h2>
              </div>
            );
          })}{" "}
      </div>
    </div>
  );
}
