import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Singles(props) {
  const [data, setData] = useState("");
  const url = window.location.href.split("/")[4];
  useEffect(() => {
    axios.get(window.location.origin + "/Discography.json").then((res) => {
      const filtered = res.data.SINGLES.filter((item) => {
        return item.url === url;
      });
      setData(filtered[0]);
    });
  }, [url]);
  return (
    <div className="discography_detail_container">
      {data !== "" && (
        <>
          {" "}
          <div className="discography_flex">
            <div className="discography_info">
              <div className="discography_left">
                <img
                  src={data.image}
                  alt=""
                  className="discography_detail_image"
                />
              </div>
              <div className="discography_right">
                <h1 className="discography_name">{data.album_name}</h1>
                <h2 className="discography_date">{data.date}</h2>
                <div className="discography_links">
                  <a href={data.spotify} target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-spotify"></i>
                  </a>
                  <a href={data.soundcloud} target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-soundcloud"></i>{" "}
                  </a>
                  <a href={data.applemusic} target="_blank" rel="noreferrer">
                    <i className="fa-solid fa-music"></i>
                  </a>
                </div>
              </div>
              <div className="discography_desc">
                <h2 className="desc_h2">
                  {props.language === "EN" && "DESCRIPTION"}{" "}
                  {props.language === "LT" && "APRAŠYMAS"}
                </h2>
                <p className="desc_p">
                  {props.language === "EN" && data.desc}{" "}
                  {props.language === "LT" && data.lt_desc}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
